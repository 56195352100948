.owlcarousel.testimonialitem {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #e8725a;
    border-radius: 15px;
    transition: .5s;
}


.testimonialitem {
    background: white;
    box-shadow: 5px 5px 30px  #868686;
    border-radius: 15px;
    margin-right: 30px;
    margin-left: 30px;
    border:5px solid rgb(115, 75, 109, 0.9);
    height:450px; 
    /* height: auto; */
    overflow: hidden;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
}
.container{
    padding-top: 2%;
}
.toptext{
    text-align: center;
}
.toptext2{
    padding-top: 2%;
    padding-bottom: 2%;
}
.flex{
    padding-top: 5%;
    align-items: center;
}
.ms {
    padding-left: 0; /* Adjust padding or spacing as needed */
    text-align:center; /* Center text content */
    padding-bottom: 0; /* Adjust spacing */
    margin-bottom: 0;
  }
.tplogo{
    padding-left: 0px;
}

.about{
    padding-top: 7%;
    padding-right: 5%;
    padding-left: 5%;
    text-align:justify;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 500;
}

/* Example: Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
    .flex {
      flex-direction: column; /* Change flex direction for smaller screens */
      align-items: center; /* Center items in columns */
    }
    .ms {
      padding-left: 0; /* Adjust padding or spacing as needed */
      text-align:center; /* Center text content */
      padding-bottom: 0; /* Adjust spacing */
      margin-bottom: 0;
    }
    .tplogo {
    
      /* padding-left: 19px;    */
      justify-items: center;
       /* Center text contentAdjust padding or spacing as needed */
    }
    .about {
      padding: 5% 5%; /* Adjust padding or spacing as needed */
    }
    .testimonialitem{
        height:auto; 
        margin-left: 15px;
        margin-right: 15px;   }
    /* Add more responsive styles as needed */
  }
