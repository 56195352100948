* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .hcparent{
    width: 100%;
  }
  
  
  .hcslider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
  }
  
.hcimg {
    width:200px;
    height: 200px;
    border-radius:10px;
  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  background-color: #fff;
  }
  
  /* .custom-dot-list-style button{
  border: none;
  background: rgb(255, 68, 68);
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: rgb(255, 68, 68) !important;
  } */
  .react-multiple-carousel__arrow{
    display: none;
  }
  .hch1{
    text-align: center;
    font-size: 30px;
    margin-bottom:5% ;
  }


@media (min-width:750px) and (max-width:799px) {
   
    .hch1{
        font-size: 2rem;
        
    }
}
@media (min-width:650px) and (max-width:749px) {
   
    .hch1{
        font-size: 2.25rem;
        
    }
}

@media (min-width:500px) and (max-width:649px) {
   
    .hch1{
        font-size: 1.75rem;
        
    }
}
@media (min-width:400px) and (max-width:499px) {
    
    .hch1{
        font-size: 1.5rem;
        
    }
}
@media (max-width:399px) {
    
    .hch1{
        font-size: 1rem;
        
    }
}
@media (min-width:500)and(max-width:600px) {
    
  .hcimg {
    width:150px;
    height: 150px;
   
  }
} 
@media (max-width:499px) {
  .hcimg {
    width:100px;
    height: 100px;
   
  }
}