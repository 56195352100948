.chooseuscontent{
    text-align: center;
    padding: 10% 10% 3%;
}
.chooseusp{
    text-align: center;
    font-size: 24px;
    margin: 5%;
    line-height: 1.5;
}
.chooseush1{
    font-size: 30px;
    margin-bottom:5% ;
}



@media (min-width:650px) and (max-width:799px) {
    .chooseusp{
        font-size: 21px; 
    }
    .chooseush1{
        font-size: 25px;
        
    }
}


@media (min-width:500px) and (max-width:649px) {
    .chooseusp{
        font-size: 20px; 
    }
    .chooseush1{
        font-size: 24px;
        
    }
}
@media (min-width:400px) and (max-width:499px) {
    .chooseusp{
        font-size: 18px; 
    }
    .chooseush1{
        font-size: 22px;
        
    }
}
@media (max-width:399px) {
    .chooseusp{
        margin: 3%;
        font-size:16px; 
    }
    .chooseush1{
        font-size: 18px;
        
    }
}