
.columns-container {
  display: flex;
}

.column {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
}

.dropdown {
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #ddd;
}
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 70%;
}

.modal-content p {
  font-size: 20px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

.modal-content button {
  padding: 12px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.modal-content button:hover {
  background-color: #0056b3;
}

/* ... (existing styles) */

.timer {
font-size: 24px;
color: #333;
margin-bottom: 20px;
}

/* ... (existing styles) */