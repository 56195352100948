.wholecourses {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 40px;
  border: 1px #000 solid;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
}
.courses:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  
}
.ug,
.pg {
  width: 48%; /* Adjust the width as needed */
  box-sizing: border-box;
  margin: 10px;
}
/* department names styles */
.cat{
  font-weight: 500;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
}
.cat:hover{
  font-weight: 400;
  background-color: #e3e2e2;
  cursor: pointer;
}
/* course list styles */
.crs{
  color: #000;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  font-weight: 300;
  margin-left: 30px;
}
.crs:hover{
  font-weight: 400;
  background-color: #e3e2e2;
  cursor: pointer;
}

.ugtxt,
.pgtxt{
  font-weight: 600;
  text-align: center;
  font-size: 25px;
}
.cou{
  list-style:none;
}
.li_items{
  list-style:none;
}
@media (max-width: 768px) {
  .ug,
  .pg {
    width: 100%;
  }
}
@media (max-width:450px){
  .courses {
    
    margin: 20px;
    border: none;
    
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    
  }
}
