/* Style for the dropdown container */
.animated-dropdown {
  width: 100%;
  font-family: Arial, sans-serif;
  border: 2px solid #009900; /* Customize border style */
  border-radius: 4px;
  padding: 10px;
  transition: all 0.3s; /* Add a smooth transition */
  position: relative;
}

/* Apply box shadow on focus */
.animated-dropdown:focus {
  box-shadow: 0 0 5px #009900;
}

/* Style for the selected options */
.selected-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s;
}

.selected-option:hover {
  background: #f0f0f0;
}

/* Style for the options container */
.options-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* Animations for selected options */
.selected-option-enter {
  opacity: 0;
  transform: translateX(20px);
}

.selected-option-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.selected-option-exit {
  opacity: 1;
  transform: translateX(0);
}

.selected-option-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 300ms, transform 300ms;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}