

article {
    --img-scale: 1.001;
    --title-color: black;
    --link-icon-translate: -20px;
    --link-icon-opacity: 0;
    position: relative;
    border-radius: 16px;
    box-shadow: none;
    background: #fff;
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
  }
  
  article a::after {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    cursor: pointer;
    content: "";
  }
  
  /* basic article elements styling */
  article h2 {
    margin: 0 0 18px 0;
    font-size: 1.9rem;
    letter-spacing: 0.06em;
    color: var(--title-color);
    transition: color 0.3s ease-out;
  }
  
  figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }
  
  article img {
    max-width: 100%;
    transform-origin: center;
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
  }
  
  .article-body {
    padding: 24px;
    line-height: 1.5;
    
    
  }
  .article-p{
    text-align: justify;
  }
  
  article a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #28666e;
  }
  
  article a:focus {
    outline: 1px dotted #28666e;
  }
  
  article a .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
  }
  .read-more{
   padding-top:10px ;
  }
  
  /* using the has() relational pseudo selector to update our custom properties */
  article:has(:hover, :focus) {
    --img-scale: 1.1;
    --title-color: #28666e;
    --link-icon-translate: 0;
    --link-icon-opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  
  /************************ 
  Generic layout (demo looks)
  **************************/
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
    padding: 48px 0;
    font-family: "Figtree", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    background-image: linear-gradient(45deg, #7c9885, #b5b682);
    min-height: 100vh;
  }
   */
  .articles {
    display: grid;
    max-width: 1200px;
    margin-inline: auto;
    padding-inline: 24px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
  }
  
  @media screen and (max-width: 960px) {
    /* article {
      container: card/inline-size;
    }
    .article-body p {
      display: none;
    } */
  }
  
  @container card (min-width: 380px) {
    .article-wrapper {
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 16px;
    }
    .article-body {
      padding-left: 0;
    }
    figure {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    figure img {
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  
  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }
  .Chead{
    text-align: center;
    font-size: 2.75rem;
    margin:5% 0 ;
  }
  @media (min-width:800px) and (max-width:1050px) {
    
    .Chead{
        font-size: 2.5rem;
        
    }
}

@media (min-width:750px) and (max-width:799px) {
    
    .Chead{
        font-size: 2rem;
        
    }
}
@media (min-width:650px) and (max-width:749px) {
   
    
    .Chead{
        font-size: 2.25rem;
        
    }
}

@media (min-width:500px) and (max-width:649px) {
    
    .Chead{
        font-size: 1.75rem;
        
    }
}
@media (min-width:400px) and (max-width:499px) {
    
    .Chead{
        font-size: 1.5rem;
        
    }
}
@media (max-width:399px) {
    
    .Chead{
        font-size: 1rem;
        
    }
}