.award1{
    width: 40%;
    margin: 5%;
}
.awards{
    text-align: center;
    
}

.content_descript{
    text-align: justify;
    font-size: 20px;
    margin: 5%;
    line-height: 1.5;
} 

@media(max-width:720px) {
    .award1{
        width: 65%
    }
    .content_descript{
        text-align: justify;
        font-size: 15px;
       } 
} 
@media (min-width:721px)and (max-width:1240px) {
    .award1{
        width: 60%
    }
}