.ACtxt{
text-align: center;
font-size: 3em;
}
.ACp{
    text-align: justify;
    margin: 2%;
    font-size: 18px;
}
.ACcontent{
    margin: 2%;
    line-height: 1.5;
}
@media (max-width:450px){
    .ACtxt{
        
        font-size: 2em;
        }
        .ACp{
            
            margin: 2%;
            font-size: 14px;
        }
}