body{
    overflow-x: hidden;
}
#root{
  margin: 0;
}
#App{
  margin: 0;
}
@keyframes whatsapp-float-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* for desktop */
  /* ... (your existing styles) */
/* .whatsapp_float {
    animation: whatsapp-float-animation 2s infinite;
  } */
  

 
    /* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
      margin-top: 10px;
    }
  
    .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
      animation: whatsapp-float-animation 2s infinite;
    }
  }
  
       /* for desktop */
       .whatsapp_float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }

    .whatsapp-icon {
        margin-top: 16px;
    }

    /* for mobile */
    @media screen and (max-width: 767px) {
        .whatsapp-icon {
            margin-top: 10px;
        }

        .whatsapp_float {
            width: 40px;
            height: 40px;
            bottom: 20px;
            right: 10px;
            font-size: 22px;
        }
    }
    .thank-you-animation {
      animation: fadeOut 3s linear forwards;
      /* Add any other styles you want for the thank you message */
    }
    
    @keyframes fadeOut {
      0% { opacity: 1; }
      90% { opacity: 1; }
      100% { opacity: 0; }
    }



/* 
insta podren
*/

@keyframes instagram-float-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* for desktop */
.float {
  /* ... (your existing styles) */
  animation: instagram-float-animation 2s infinite;
}



  /* for mobile */
@media screen and (max-width: 767px) {
  .instagram-icon {
    margin-top: 10px;
  }

  .instagram_float {
    width: 40px;
    height: 40px;
    bottom: 50px;
    right: 10px;
    font-size: 22px;
    animation: instagram-float-animation 2s infinite;
  }
}

     /* for desktop */
     .instagram_float {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 120px;
      right: 40px;
      background-color: #C13584;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 2px 2px 3px #999;
      z-index: 100;
  }

  .instagram-icon {
      margin-top: 16px;
  }

  /* for mobile */
  @media screen and (max-width: 767px) {
      .instagram-icon {
          margin-top: 10px;
      }

      .instagram_float {
          width: 40px;
          height: 40px;
          bottom: 80px;
          right: 10px;
          font-size: 22px;
      }
  }
  .thank-you-animation {
    animation: fadeOut 3s linear forwards;
    /* Add any other styles you want for the thank you message */
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
    