/* StudyAbroadPage.css */

  
  .SA {
    padding: 10px;
  }
  
.SA h1{
  text-align: center;
  margin: 30px 0;
  font-size: 40px;
}

.countrysec p{
  text-align: center;
  font-size: 20px;
  margin: 5px 0;
}
.contactsec h2{
  font-size: 35px;
}
.contactsec p{
  font-size: 25px;
  margin: 20px;
}
.contactsec{
  line-height: 1.5;
}

  .countrysec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  /* .flags{
    text-align: center;
  }
   */
  /* .country-card {
    text-align: center;
    margin: 10px;
  } */
  
  .countrycard{
    max-width: 100%;
    width: 150px;

    height: 100px;
  }
  
  .contactsec{
    margin-top: 40px;
    text-align: center;
  }
  
  .contactsec p {
    color: #555;
  }
  
  .contactbut {
    background-color: #000;
    color: #fff;
    font-size: 25px;
    padding: 20px 40px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }
  .contactbut:hover {
    background-color: #363636;
    cursor: pointer;
  }
  /* .sa{
    text-align: center;
  } */
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .country-card {
      width: 45%;
    }
  }
  