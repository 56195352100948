
.abtcontent{
    text-align: justify;
    font-size: 1.563rem;
    margin: 5%;
    line-height: 1.5;
}
.abth2{
    font-size: 3.125rem;
    text-align: center;
    padding: 5px ;
    margin-top: 20px;
}

.abtposter{
    background: linear-gradient(to right, rgb(115, 75, 109,0.9), rgb(66, 39, 90, 0.9));
    padding: 100px 0;
    color: #fff;
    margin-top:0px;
}

.abtcontainer {
    display: flex;
    align-items: center;
}


.founderimg {
    width: 250px;
    height: 250px;
    background-image: url("/src/Assets/Media/edurampic.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-top:100px;
    margin-right:20px ;
}
.fndrcontent {
    flex: 1;
}

/* for cards */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}


.cardcontainer {
    padding: 50px 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.parent {
    height: 500px;
    width: 300px;
    padding: 20px;
    perspective: 1000px;
}


.content-box {
    height:500px;
    width: 100%;    
    background: linear-gradient(to right, rgb(115, 75, 109,0.9), rgb(66, 39, 90, 0.9));
    border-radius: 10px;
    box-shadow: rgba(0, 115, 255, 0.925) 0px 20px 50px -25px,;
    transition: all .5s ease-in-out;
    padding: 50px 25px 25px 25px;
    transform-style: preserve-3d;
    

.card-title {
        color: white;
        padding-bottom: 20px;
        font-size: 25px;
        font-weight: 900;
        text-align: center;
        transition: all 0.5s ease-in-out;
        transform: translate3d(0px, 0px, 20px);
    }

    .card-content {
        padding-top: 25px;
        font-size: 20px;
        text-align: left;
        color: rgb(227, 227, 227);
        transition: all 0.5s ease-in-out;
        transform: translate3d(0px, 0px, 20px);
        
    }

    
}
.content-box:hover{
    background: linear-gradient(to right, rgba(78, 51, 74, 0.9), rgba(49, 29, 67, 0.9));
}

@media (max-width:950px){
    .abtcontainer {
        display:block;
        align-items: center;
    }
    .founderimg{
        position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -20%);
    }
}

@media (max-width:601px) {
    .abth2{
        font-size: 2.5rem;
    }
    .abtcontent{
        text-align: justify;
        font-size: 1rem;
        margin: 5%;
    }
}