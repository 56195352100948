.homegradient {
    background-image:
        linear-gradient(to right, rgb(115, 75, 109, 0.9), rgb(66, 39, 90, 0.9)),
        url('../../../Assets/Media/Home BG.jpg');
    background-position: center bottom;
    width              : auto;
    height             : 100%;
    background-size    : cover;
    color              : white;
    padding            : 20px 20px 90px;
}

.h1 {
    text-align  : left;
    font-size   : 70px;
    line-height : 1.5;
    padding-top : 3%;
    padding-left: 3%;
}

.btn {
    padding         : 1% 2%;
    margin          : 0 3%;
    border-radius   : 10px;
    background-color: #5c3082;
    color           : #fff;
    border          : 1px solid white;
    font-size       : 25px;
    cursor          : pointer;
}

.btn:hover {
    background-color: #7124b5;
}

.btn2 {
    display: flex;
}

.button {
    margin-top: 5%;
}
@media (min-width:768px) and (max-width:992px) {
    .h1 {
        font-size: 50px;
    }
}

@media (min-width:601px) and (max-width:768px) {
    .h1 {
        font-size: 40px;
    }

    .btn {
        font-size: 20px;
    }
}

@media (max-width:601px) {
    .h1 {
        font-size: 30px;
    }

    .btn {
        font-size: 15px;
    }
}