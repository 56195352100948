/* Your existing styles */

.content {
  display: flex;
  justify-content: flex-end;
  gap: 4%;
  padding: 2% 3% 2% 0;
  color: #fff;
  background: linear-gradient(to right, rgba(115, 75, 109, 0.9), rgba(66, 39, 90, 0.9));
  margin-bottom: 0;
}

.decoration {
  color: #ffffff;
  text-decoration: none;
}

.decoration:hover {
  color: #bababa;
}

.decoration.current-page {
  color: rgba(231, 234, 235, 0.557);
}

.navbar {
  margin-bottom: 0;
  width: 100%;
}

.toggle-btn {
  display: none;
}

.toggle {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.5em 1em;
  font-size: 15px;
}

@media (max-width: 768px) {
  .toggle-btn {
    display: block;
    background: linear-gradient(to right, rgba(115, 75, 109, 0.9), rgba(66, 39, 90, 0.9));
    margin: 0;
  }

  .content {
    display:none;
    flex-direction: column;
    position:inherit;  /*before absolute*/
    top: 60px;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, rgba(115, 75, 109, 0.9), rgba(66, 39, 90, 0.9));
    text-align: center;
  }

  .content.active {
    display: flex;
  }

  .decoration {
    padding-bottom: 5%;
    text-decoration: none; /* Remove underline for links in mobile view */
  }

  /* Hide underline for mobile view */
  .decoration::after {
    display: none;
  }
}

/* Additional styles */

.decoration {
  position: relative;
}

.decoration::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  background-color:  rgb(255, 249, 254); /* Color of the underline */
  transition: width 0.3s ease-out;
}

.decoration:hover::after,
.decoration.current-page::after {
  width: 100%;
}
