.radio-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .radio-label {
    display: flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s;
    border: 2px solid #009900;
    border-radius: 5px;
    padding: 5px;
  }
  
  .radio-label.selected {
    background-color: #009900;
    color: #fff;
    transform: scale(1.1); /* Increase the scale for a stronger effect */
  }

  
  /* 
  .radio-container label {
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.3s;
  overflow: hidden;
}

.radio-container label.selected {
  transform: translateX(5px); 
   Adjust the distance for your desired slide effect 
  background-color: #009900;
  color: #fff;
}

  
  */