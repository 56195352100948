.Tcontent{
  margin-bottom: 5%;
}


.Tarticle {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.Tarticle a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}
.Thead{
  text-align: center;
  font-size: 2.75rem;
  margin-bottom:5% ;
}

/* basic article elements styling */
.Th2 {
  margin: 0 0 18px 0;
  font-family: "Bebas Neue", cursive;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
  text-align: center;
}
.Ticon{
  align-items: center;
  font-size: 50px;
  margin: 0%;
}


.Tarticle-body {
  padding: 24px;
}



/* using the has() relational pseudo selector to update our custom properties */
.Tarticle:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}


/************************ 
Generic layout (demo looks)
**************************/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* body {
  margin: 0;
  padding: 48px 0;
  font-family: "Figtree", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  background-image: linear-gradient(45deg, #7c9885, #b5b682);
  min-height: 100vh;
}
 */
.Tarticles {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

@media screen and (max-width: 960px) {
  /* article {
    container: card/inline-size;
  }
  .article-body p {
    display: none;
  } */
}

@container card (min-width: 380px) {
  .Tarticle-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }
  .article-body {
    padding-left: 0;
  }
  .Tfigure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .Tfigure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}
@media (min-width:800px) and (max-width:1050px) {
      
  .Thead{
      font-size: 2.5rem;
      
  }
}

@media (min-width:750px) and (max-width:799px) {
  
  .Thead{
      font-size: 2rem;
      
  }
}
@media (min-width:650px) and (max-width:749px) {
 
  
  .Thead{
      font-size: 2.25rem;
      
  }
}

@media (min-width:500px) and (max-width:649px) {
  
  .Thead{
      font-size: 1.75rem;
      
  }
}
@media (min-width:400px) and (max-width:499px) {
  
  .Thead{
      font-size: 1.5rem;
      
  }
}
@media (max-width:399px) {
  
  .Thead{
      font-size: 1rem;
      
  }
}